import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Layout, Menu, Avatar, Dropdown, Badge } from "antd";
import {
  LogoutOutlined,
  UserOutlined,
  BellOutlined,
  LockOutlined,
  FileTextOutlined,
  PlaySquareOutlined,
  PicLeftOutlined,
} from "@ant-design/icons";
import { requestLogout } from "../core/redux/auth/auth.actions";

import "./AppLayout.css";
import routes from "../core/constants/routes";
import { useGetProfile } from "../core/hooks/queries/users.queries";

const { Header, Content, Sider } = Layout;

const statics = {
  sidebarWidth: 200,
  collapsedSidebarWidth: 72,
  headerHeight: 72,
};

const AppLayout = (props: any) => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const dispatch = useDispatch();
  const history = useHistory();
  const profileQuery = useGetProfile();

  const avatarMenu = (
    <Menu>
      {/*<Menu.Item key="0" icon={<UserOutlined />}>*/}
      {/*  <Link to={routes.about}>Account</Link>*/}
      {/*</Menu.Item>*/}
      {/*<Menu.Divider />*/}
      <Menu.Item
        key="1"
        icon={<LogoutOutlined />}
        onClick={() => {
          dispatch(requestLogout());
        }}
      >
        Logout
      </Menu.Item>
    </Menu>
  );

  const notifications = (
    <Menu>
      <div style={{ padding: 5 }}>To be implemented</div>
    </Menu>
  );

  return (
    <Layout style={{ minHeight: "100vh", backgroundColor: "white" }}>
      <Sider
        collapsible
        collapsed={isCollapsed}
        onCollapse={setIsCollapsed}
        collapsedWidth={statics.collapsedSidebarWidth}
        theme={"light"}
        width={statics.sidebarWidth}
        style={{
          height: "100%",
          position: "fixed",
          left: 0,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              height: statics.headerHeight,
              textAlign: "center",
              verticalAlign: "middle",
              lineHeight: `${statics.headerHeight}px`,
            }}
          >
            <Avatar
              shape="square"
              size={64}
              style={{ padding: 10 }}
              src="https://svgshare.com/i/Wf8.svg"
            />
          </div>

          <Menu
            mode="inline"
            defaultSelectedKeys={["1"]}
            defaultOpenKeys={["sub1"]}
            className="gad-cb-menu-style"
            style={{
              width: "auto",
              border: 0,
              paddingTop: 24,
              overflow: "auto",
              height: "100%",
            }}
          >
            <Menu.Item key="1" icon={<UserOutlined />} onClick={() => history.push(routes.users)}>
              Users
            </Menu.Item>
            <Menu.Item key="2" icon={<LockOutlined />} onClick={() => history.push(routes.roles)}>
              Roles
            </Menu.Item>
            <Menu.Item
              key="3"
              icon={<PlaySquareOutlined />}
              onClick={() => history.push(routes.media)}
            >
              Media
            </Menu.Item>
            <Menu.Item
              key="4"
              icon={<FileTextOutlined />}
              onClick={() => history.push(routes.texts)}
            >
              Texts
            </Menu.Item>
            <Menu.Item key="5" icon={<PicLeftOutlined />} onClick={() => history.push(routes.news)}>
              News
            </Menu.Item>
          </Menu>

          <Menu
            mode="inline"
            className="gad-cb-menu-style"
            style={{
              position: "sticky",
              width: "auto",
              border: 0,
              bottom: 0,
            }}
          >
            <Menu.Item
              key="1"
              icon={<LogoutOutlined />}
              onClick={() => {
                dispatch(requestLogout());
              }}
            >
              Logout
            </Menu.Item>
          </Menu>
        </div>
      </Sider>

      <Layout
        style={{
          marginLeft: isCollapsed ? statics.collapsedSidebarWidth : statics.sidebarWidth,
          backgroundColor: "white",
        }}
      >
        <Header
          style={{
            backgroundColor: "white",
            height: statics.headerHeight,
            justifyContent: "space-between",
            display: "flex",
            alignItems: "center",
          }}
        >
          <div>
            {/*<Input.Search*/}
            {/*  placeholder="Search"*/}
            {/*  allowClear*/}
            {/*  onSearch={() => {}}*/}
            {/*  style={{ width: 200, margin: '0 10px' }}*/}
            {/*/>*/}
          </div>

          <div>
            <Dropdown overlay={notifications} trigger={["click"]} placement={"bottomRight"}>
              <Badge dot={false}>
                <BellOutlined onClick={() => {}} style={{ fontSize: 20 }} />
              </Badge>
            </Dropdown>

            <Dropdown overlay={avatarMenu} trigger={["click"]} placement={"bottomRight"} arrow>
              <Avatar
                size="large"
                gap={30}
                style={{
                  marginLeft: 30,
                }}
              >
                {profileQuery.data?.firstName?.charAt(0)}
                {profileQuery.data?.lastName?.charAt(0)}
              </Avatar>
            </Dropdown>
          </div>
        </Header>

        <Content style={{ padding: "0 16px", backgroundColor: "#F5F5F5", borderTopLeftRadius: 20 }}>
          {props.children}
        </Content>
      </Layout>
    </Layout>
  );
};

export default AppLayout;
