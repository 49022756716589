import React from "react";
import { Provider } from "react-redux";
import { persistor, store } from "./core/redux/store";
import "./App.less";
import { PersistGate } from "redux-persist/integration/react";
import Root from "./Root";
import { QueryCache, ReactQueryCacheProvider } from "react-query";

const queryCache = new QueryCache();

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        {(isReduxLoaded: boolean) => (
          <ReactQueryCacheProvider queryCache={queryCache}>
            <Root isReduxLoaded={isReduxLoaded} />
          </ReactQueryCacheProvider>
        )}
      </PersistGate>
    </Provider>
  );
}

export default App;
