import React, { useState } from "react";
import { PageHeader, Table, Input } from "antd";
import moment from "moment";
import CreateNewsModal from "../News/CreateNewsModal";
import { useNewsList } from "../../core/hooks/queries/news.queries";
import { DeleteOutlined } from "@ant-design/icons";
import { deleteById } from "../../core/services/news.service";
import { useSelector } from "react-redux";
import { RootState } from "../../core/redux/store";

const NewsList = () => {
  const token = useSelector((state: RootState) => state.authReducer.token);
  const [searchQuery, seySearchQuery] = useState("");
  const allNews = useNewsList({
    searchQuery,
  });

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      render: (id: number) => id,
    },
    {
      title: "Title",
      dataIndex: "title",
      render: (name: string) => name,
    },
    {
      title: "Created at",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (date: string) => moment(date).format("DD MMM YYYY mm:ss"),
    },
    {
      title: "Updated at",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (date: string) => moment(date).format("DD MMM YYYY mm:ss"),
    },
    {
      title: "Actions",
      dataIndex: "id",
      key: "id",
      // eslint-disable-next-line react/display-name
      render: (id: number) => (
        <>
          <DeleteOutlined
            onClick={async () => {
              await deleteById(token, id).then(() => {
                void allNews.refetch();
              });
            }}
          />
        </>
      ),
    },
  ];

  return (
    <div>
      <PageHeader
        title={"News"}
        extra={[
          <Input.Search
            key={1}
            placeholder="Search for news"
            allowClear
            onChange={(event) => seySearchQuery(event.target.value)}
            value={searchQuery}
            style={{ width: 200, margin: "0 10px" }}
          />,
          <CreateNewsModal
            key={2}
            onFinish={() => {
              void allNews.refetch();
            }}
          />,
        ]}
      >
        <Table
          rowKey={"id"}
          columns={columns}
          dataSource={allNews.data}
          onRow={(record) => ({
            // onClick: () => history.push(routes.event.generate(record.id)),
          })}
        />
      </PageHeader>
    </div>
  );
};

export default NewsList;
