import { createStore, applyMiddleware, combineReducers, Action } from "redux";
import thunk, { ThunkAction } from "redux-thunk";
import storage from "redux-persist/lib/storage";
import { persistStore, persistReducer } from "redux-persist";
import { PersistConfig } from "redux-persist/es/types";
import { whitelist, blacklist } from "./reducers";

// Create the root reducer
const rootReducer = combineReducers({
  ...whitelist,
  ...blacklist,
});

// Create a type for the root state
export type RootState = ReturnType<typeof rootReducer>;

// Create a type for the Thunk functions
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

// Create the 'redux-thunk' middleware
const thunkMiddleware = applyMiddleware(thunk);

// Create the redux-persist configuration
const persistConfig: PersistConfig<any> = {
  // Persistor name
  key: "root",

  // Set the storage engine
  storage,

  // What to save
  whitelist: Object.keys(whitelist),

  // What to omit
  blacklist: Object.keys(blacklist),
};

// Create the 'redux-persist' persisted reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Create the 'redux' store
const store = createStore(persistedReducer, thunkMiddleware);

// Create the 'redux-persist' persistor
const persistor = persistStore(store);

export { store, persistor };
