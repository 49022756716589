import React from "react";
import { Route, Switch } from "react-router-dom";
import routes from "../core/constants/routes";
import PrivateRoute from "../core/components/PrivateRoute";
import AppLayout from "../layouts/AppLayout";
import About from "../pages/About";
import Home from "../pages/Home";
import EventView from "../components/Users/User";
import UserList from "../components/Users/UserList";
import RoleList from "../components/Roles/RoleList";
import MediaList from "../components/Media/MediaList";
import NewsList from "../components/News/NewsList";
import TextList from "../components/Texts/TextList";

const AppRoutes = () => (
  <PrivateRoute
    exact
    // TODO: make a non-private option to define only what's not
    path={[
      routes.home,
      routes.about,
      routes.users,
      routes.user.route,
      routes.roles,
      routes.media,
      routes.texts,
      routes.news,
    ]}
  >
    <AppLayout>
      <Switch>
        <Route exact path={routes.home} component={Home} />
        <Route path={routes.about} component={About} />

        <Route exact path={routes.users} component={UserList} />
        <Route exact path={routes.user.route} component={EventView} />

        <Route exact path={routes.roles} component={RoleList} />

        <Route exact path={routes.media} component={MediaList} />
        <Route exact path={routes.texts} component={TextList} />
        <Route exact path={routes.news} component={NewsList} />

        {/* TODO: add 404 page */}
      </Switch>
    </AppLayout>
  </PrivateRoute>
);

export default AppRoutes;
