import React from "react";
import { Button, Card, Checkbox, Form, Input, Row, Typography } from "antd";
import { MailOutlined } from "@ant-design/icons";
import { requestLogin } from "../../core/redux/auth/auth.actions";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../core/redux/store";
import { Link, useLocation, Redirect } from "react-router-dom";
import routes from "../../core/constants/routes";

const itemStyle = { marginBottom: 16 };
const inputStyle = { height: 40, borderRadius: 10 };

const getLabel = (text: string) => {
  return <span style={{ fontSize: 15, fontFamily: "Karla" }}>{text}</span>;
};

const Login = (props: any) => {
  const location = useLocation<any>(); // TODO: add type
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const reduxState = useSelector((state: RootState) => ({
    token: state.authReducer.token,
  }));

  // If a token is present then the user successfully logged in
  // and we should redirect him to the previous page or Home
  if (reduxState.token) {
    return <Redirect to={location.state?.from || routes.home} />;
  }

  const onFormLayoutChange = ({ layout }: any) => {
    console.log(layout);
  };

  const onCheck = async () => {
    try {
      const { email, password } = await form.validateFields();

      dispatch(requestLogin({ username: email, password }));
    } catch (errorInfo) {
      console.log("Authentication failed:", errorInfo);
    }
  };

  const page = (
    <Row justify={"center"} align={"middle"} style={{ minHeight: "100vh" }}>
      <Card style={{ width: 432, borderRadius: 10 }} bodyStyle={{ padding: 56 }}>
        <Row justify={"center"} style={{ marginBottom: 32 }}>
          {/*TODO: Change font size & family*/}
          <Typography.Text style={{ fontSize: 24, marginBottom: 8 }}>Admin Login</Typography.Text>
        </Row>

        <Form
          layout={"vertical"}
          form={form}
          initialValues={{
            layout: "vertical",
          }}
          onFinish={(values) => {
            console.log("==>", values);
          }}
          onValuesChange={onFormLayoutChange}
        >
          <Form.Item
            name={"email"}
            label={getLabel("Email")}
            rules={[{ required: true, message: "Enter a valid email" }]}
            required={false}
            style={itemStyle}
          >
            <Input
              placeholder={"e.g. me@example.com"}
              style={inputStyle}
              suffix={<MailOutlined />}
            />
          </Form.Item>
          <Form.Item
            name={"password"}
            label={getLabel("Password")}
            rules={[{ required: true, message: "Please choose a password" }]}
            required={false}
            style={itemStyle}
          >
            <Input.Password placeholder={"e.g. HardToGuess#1957."} style={inputStyle} />
          </Form.Item>

          <Row justify={"center"}>
            <Form.Item>
              <Button
                onClick={onCheck}
                type="primary"
                style={{
                  backgroundColor: "#498B9B",
                  height: "auto",
                  borderRadius: 20,
                  paddingTop: 7,
                  paddingBottom: 7,
                  paddingLeft: 32,
                  paddingRight: 32,
                  fontSize: 15,
                  fontFamily: "Karla",
                }}
              >
                Login
              </Button>
            </Form.Item>
          </Row>
        </Form>
      </Card>
    </Row>
  );

  return page;
};

export default Login;
