import React, { useState } from "react";
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  PageHeader,
  Row,
  Select,
  Space,
  Steps,
  Switch,
  Upload,
} from "antd";
import {
  AppstoreOutlined,
  ArrowLeftOutlined,
  ArrowRightOutlined,
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  GlobalOutlined,
  PlusOutlined,
  TagOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { RcFile } from "antd/lib/upload";
import { mediaCategoryMappings, mediaTypeMappings } from "./MediaList";
import prettyBytes from "pretty-bytes";
import { create } from "../../core/services/media.service";
import { useSelector } from "react-redux";
import { RootState } from "../../core/redux/store";

const itemStyle = { marginBottom: 16 };

export type CreateMediaModalProps = {
  onFinish?: () => void;
};

const CreateMediaModal: React.FC<CreateMediaModalProps> = (props) => {
  const token = useSelector((state: RootState) => state.authReducer.token);
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [step, setStep] = useState(0);
  const [addText, setAddText] = useState(false);
  const [detailsForm] = Form.useForm();
  const [textForm] = Form.useForm();
  const [detailsFormValues, setDetailsFormValues] = useState({});
  const [textFormValues, setTextFormValues] = useState({});
  const [file, setFile] = useState<RcFile | undefined>();

  const toggleModal = () => setShowModal(!showModal);

  const onCancel = () => {
    setShowModal(false);
    setFile(undefined);
    setDetailsFormValues({});
    setTextFormValues({});
    setStep(0);
  };

  /**
   * Check the form and send the values
   */
  const onSubmit = async (value: any) => {
    setIsLoading(true);

    if (!file) {
      throw new Error("Choose a file");
    }

    try {
      const toSend = {
        ...detailsFormValues,
        file,
        textJson: addText ? JSON.stringify(textFormValues) : undefined,
      };

      // @ts-ignore
      await create(token, toSend);

      if (props?.onFinish) props.onFinish();

      onCancel();
    } catch (errorInfo) {
      console.log("Failed:", errorInfo);
    } finally {
      setIsLoading(false);
    }
  };

  const detailsFormView = (
    <>
      <PageHeader title="Add media details" />

      <Form
        form={detailsForm}
        layout={"vertical"}
        onValuesChange={(_, values) => setDetailsFormValues(values)}
      >
        <Form.Item
          required
          shouldUpdate
          name={"title"}
          label="Danish Title"
          style={itemStyle}
          // rules={[{ required: true, message: "You must enter the title" }]}
        >
          <Input placeholder={"Media danish title"} />
        </Form.Item>

        <Form.Item shouldUpdate name={"enTitle"} label="English Title" style={itemStyle}>
          <Input placeholder={"Media english title"} />
        </Form.Item>

        <Form.Item
          shouldUpdate
          name={"category"}
          label="Category"
          style={itemStyle}
          rules={[{ required: true, message: "You must select the category" }]}
        >
          <Select
            allowClear
            suffixIcon={<AppstoreOutlined />}
            placeholder="Category"
            // @ts-ignore
            options={Object.entries(mediaCategoryMappings).map(([value, label]) => ({
              label,
              value,
            }))}
          />
        </Form.Item>

        <Form.Item
          shouldUpdate
          name={"type"}
          label="Type"
          style={itemStyle}
          rules={[{ required: true, message: "You must select the type" }]}
        >
          <Select
            allowClear
            suffixIcon={<TagOutlined />}
            placeholder="Type"
            // @ts-ignore
            options={Object.entries(mediaTypeMappings).map(([value, label]) => ({
              label,
              value,
            }))}
          />
        </Form.Item>

        <Form.Item name={"description"} label="Description" style={itemStyle}>
          <Input.TextArea placeholder={"Write a description..."} />
        </Form.Item>

        <Form.Item name={"isPublic"} valuePropName={"checked"} style={itemStyle}>
          <Checkbox>
            <GlobalOutlined /> Public
          </Checkbox>
        </Form.Item>
      </Form>
    </>
  );

  const textFormView = (
    <>
      <PageHeader
        title="Write a text"
        extra={[
          <Switch
            key={1}
            checked={addText}
            onChange={(checked) => {
              setAddText(checked);
            }}
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
            defaultChecked
          />,
        ]}
      />

      {addText && (
        <Form
          form={textForm}
          layout={"vertical"}
          onValuesChange={(_, values) => setTextFormValues(values)}
        >
          <Form.Item
            required
            name={"title"}
            label="Title"
            style={itemStyle}
            // rules={[{ required: true, message: "You must enter the title" }]}
          >
            <Input placeholder={"Media title"} />
          </Form.Item>

          <Form.Item shouldUpdate name={"enTitle"} label="English Title" style={itemStyle}>
            <Input placeholder={"Media english title"} />
          </Form.Item>

          <Form.Item
            name={"en_male"}
            label="English male text"
            style={itemStyle}
            rules={[{ required: true, message: "You must enter the english male text" }]}
          >
            <Input.TextArea
              placeholder={"Write your english male text..."}
              onChange={() => textForm.validateFields()}
            />
          </Form.Item>

          <Form.Item
            name={"en_female"}
            label="English female text"
            style={itemStyle}
            rules={[{ required: true, message: "You must enter the english female text" }]}
          >
            <Input.TextArea
              placeholder={"Write your english male text..."}
              onChange={() => textForm.validateFields()}
            />
          </Form.Item>

          <Form.Item name={"da_male"} label="Danish male text" style={itemStyle}>
            <Input.TextArea
              placeholder={"Write your english male text..."}
              onChange={() => textForm.validateFields()}
            />
          </Form.Item>

          <Form.Item name={"da_female"} label="Danish female text" style={itemStyle}>
            <Input.TextArea
              placeholder={"Write your english male text..."}
              onChange={() => textForm.validateFields()}
            />
          </Form.Item>

          <Form.List name="keys">
            {(fields, { add, remove }) => {
              return (
                <>
                  <PageHeader
                    title="Add dynamic characters"
                    extra={[
                      <Button key={1} type="dashed" onClick={() => add()}>
                        <PlusOutlined /> Add field
                      </Button>,
                    ]}
                  />

                  {fields.map((field, index, total) => (
                    <div key={field.key}>
                      <Divider orientation={"left"}>
                        <DeleteOutlined style={{ color: "red" }} onClick={() => remove(index)} />{" "}
                        Character {index + 1}
                      </Divider>

                      <Row gutter={10}>
                        <Col span={8}>
                          <Form.Item
                            name={[index, "key"]}
                            label={`Replacement key`}
                            rules={[
                              { required: true, message: "You must enter the replacement key" },
                              {
                                required: true,
                                validator: async (rule, value, callback) => {
                                  const enMale = textForm?.getFieldValue("en_male") || undefined;
                                  const enFemale =
                                    textForm?.getFieldValue("en_female") || undefined;
                                  const daMale = textForm?.getFieldValue("da_male") || undefined;
                                  const daFemale =
                                    textForm?.getFieldValue("da_female") || undefined;

                                  if (enMale && !enMale.includes(value)) {
                                    throw new Error();
                                  }

                                  if (enFemale && !enFemale.includes(value)) {
                                    throw new Error();
                                  }

                                  if (daMale && !daMale.includes(value)) {
                                    throw new Error();
                                  }

                                  if (daFemale && !daFemale.includes(value)) {
                                    throw new Error();
                                  }
                                },
                                message:
                                  "You didn't use this key in your texts (en male, en female, da male or da female)",
                              },
                            ]}
                          >
                            <Input placeholder="Key" />
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item
                            name={[index, "gender"]}
                            label={`Character gender`}
                            rules={[
                              { required: true, message: "You must specify the character gender" },
                            ]}
                          >
                            <Input placeholder="Gender" />
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item
                            name={[index, "default"]}
                            label={`Default name`}
                            rules={[
                              {
                                required: true,
                                message: "You must specify the character's default name",
                              },
                            ]}
                          >
                            <Input placeholder="Default" />
                          </Form.Item>
                        </Col>
                      </Row>
                    </div>
                  ))}
                </>
              );
            }}
          </Form.List>
        </Form>
      )}
    </>
  );

  const getStepCurrentStatus = (
    currentStep: number,
    chosenStep: number,
    max?: boolean,
  ): "finish" | "wait" | "process" | "error" | undefined => {
    if (max && chosenStep === currentStep) return "finish";
    if (chosenStep === currentStep) return "process";
    if (chosenStep < currentStep) return "finish";
    if (chosenStep > currentStep) return "wait";
  };

  const steps = [
    <Steps.Step
      key={0}
      disabled
      title="Upload"
      description={file && `1 file of ${prettyBytes(file.size)}`}
      status={getStepCurrentStatus(step, 0)}
    />,
    <Steps.Step
      key={1}
      disabled
      title="Add media details"
      status={getStepCurrentStatus(step, 1)}
    />,
    <Steps.Step key={2} disabled title="Write a text" status={getStepCurrentStatus(step, 2)} />,
    <Steps.Step key={3} disabled title="Done" status={getStepCurrentStatus(step, 3, true)} />,
  ];

  return (
    <>
      <Button type="primary" icon={<PlusOutlined />} onClick={toggleModal}>
        Create media
      </Button>

      <Modal
        title="Create media"
        visible={showModal}
        width={1000}
        footer={null}
        onCancel={onCancel}
        centered
        style={{
          borderRadius: 10,
        }}
      >
        {/*Steps navigation*/}
        <Steps
          type="navigation"
          size="small"
          current={step}
          onChange={(newStep) => setStep(newStep)}
          className="site-navigation-steps"
          style={{ marginBottom: 20 }}
        >
          {steps}
        </Steps>

        {/*Step 0: Upload*/}
        {step === 0 && (
          <div>
            <Upload
              onRemove={(file) => {
                setFile(undefined);
              }}
              beforeUpload={(file) => {
                setFile(file);
                setStep(1);
                return false;
              }}
            >
              <Button icon={<UploadOutlined />}>Upload audio file</Button>
            </Upload>
          </div>
        )}

        {/*Step 1: Details*/}
        {step === 1 && file && detailsFormView}

        {/*Step 2: Text*/}
        {step === 2 && file && textFormView}

        {/*Step 3: Done*/}

        {/*Footer*/}
        {file && (
          <Row justify={"end"}>
            <Form.Item style={{ marginBottom: 0 }}>
              <Space
                size={16}
                style={{
                  alignItems: "flex-end",
                }}
              >
                {step === 0 && (
                  <Button htmlType="button" loading={isLoading} onClick={onCancel}>
                    Cancel
                  </Button>
                )}

                {step > 0 && (
                  <Button
                    htmlType="button"
                    icon={<ArrowLeftOutlined />}
                    onClick={() => {
                      setStep(step - 1);
                    }}
                    loading={isLoading}
                  >
                    Back
                  </Button>
                )}

                {step < steps.length - 1 && (
                  <Button
                    type="primary"
                    icon={<ArrowRightOutlined />}
                    onClick={async () => {
                      if (step === 1) {
                        if (await detailsForm.validateFields()) {
                          setStep(step + 1);
                        }

                        return;
                      } else if (step === 2 && addText) {
                        if (await textForm.validateFields()) {
                          setStep(step + 1);
                        }

                        return;
                      } else {
                        setStep(step + 1);
                      }
                    }}
                    loading={isLoading}
                  >
                    Next
                  </Button>
                )}

                {step === steps.length - 1 && (
                  <Button
                    type="primary"
                    loading={isLoading}
                    icon={<PlusOutlined />}
                    onClick={onSubmit}
                  >
                    Create
                  </Button>
                )}
              </Space>
            </Form.Item>
          </Row>
        )}
      </Modal>
    </>
  );
};

export default CreateMediaModal;
