import axios, { AxiosInstance } from "axios";
import {
  API_ALL_ROLES_PATH,
  API_BASE_URL,
  API_ROLES_PATH,
  API_TIMEOUT,
} from "../constants/constants";
import { Role } from "../../types";

// Create the Axios instance
const service: AxiosInstance = axios.create({
  baseURL: API_BASE_URL + API_ROLES_PATH,
  timeout: API_TIMEOUT,
});

/**
 * Get user's profile
 * @param token
 * @param searchQuery
 */
export const getAllRoles = async (token: string | null, searchQuery?: string): Promise<Role[]> => {
  const response = await service.get<Role[]>(API_ALL_ROLES_PATH, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      searchQuery,
    },
  });

  // TODO
  //  - Catch errors
  //  - Validate the response

  return response.data;
};
