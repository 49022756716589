import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { useQuery } from "react-query";
import { User } from "../../../types";
import { getAllUsers, getProfile } from "../../services/users.service";

const MY_PROFILE_KEY = "MY_PROFILE_KEY";
const ALL_USERS_KEY = "ALL_USERS_KEY";

export const useGetProfile = () => {
  const reduxState = useSelector((state: RootState) => ({
    token: state.authReducer.token,
  }));

  return useQuery<User, Error>([MY_PROFILE_KEY], () => {
    return getProfile(reduxState.token);
  });
};

export const useAllUsers = (searchQuery?: string) => {
  const reduxState = useSelector((state: RootState) => ({
    token: state.authReducer.token,
  }));

  return useQuery<User[], Error>([ALL_USERS_KEY, searchQuery], () => {
    return getAllUsers(reduxState.token, searchQuery);
  });
};
