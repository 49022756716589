export const ENV: "local" | "development" | "staging" | "production" = "development";
// export const ENV: "local" | "development" | "staging" | "production" = "production";

// export const API_BASE_URL = "https://shaizeman.loca.lt";
// export const API_BASE_URL = "http://localhost:3100";
export const API_BASE_URL = "https://api.lullabee.dk";
export const API_TIMEOUT = 30000;

export const API_AUTH_PATH = "/auth";
export const API_AUTH_REGISTER_PATH = "/register";
export const API_AUTH_LOGIN_PATH = "/login";
export const API_AUTH_RESET_PASSWORD_PATH = "/reset-password";

export const API_USERS_PATH = "/users";
export const API_USERS_PROFILE_PATH = "/me";
export const API_ALL_USERS_PATH = "/list";

export const API_ROLES_PATH = "/roles";
export const API_ALL_ROLES_PATH = "/list";

export const API_MEDIA_PATH = "/media";
export const API_ALL_MEDIA_PATH = "/list";

export const API_TEXTS_PATH = "/texts";
export const API_ALL_TEXTS_PATH = "/list";

export const API_NEWS_PATH = "/news";
export const API_ALL_NEWS_PATH = "/list";
