import React from "react";
import routes from "../core/constants/routes";
import AuthLayout from "../layouts/AuthLayout";
import { Route } from "react-router-dom";
import Login from "../pages/Auth/Login";
import ResetPassword from "../pages/Auth/ResetPassword";
// import Register from '../pages/Auth/Register';

const AuthRoutes = () => (
  <Route path={[routes.register, routes.login, routes.resetPassword]}>
    <AuthLayout>
      {/*<Route path={routes.register} component={Register} />*/}
      <Route path={routes.login} component={Login} />
      <Route path={routes.resetPassword} component={ResetPassword} />
    </AuthLayout>
  </Route>
);

export default AuthRoutes;
