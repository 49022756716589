import React, { useState } from "react";
import { Button, Card, Form, Input, notification, Row, Typography } from "antd";
import { useSelector } from "react-redux";
import { RootState } from "../../core/redux/store";
import { Link, Redirect, useLocation } from "react-router-dom";
import routes from "../../core/constants/routes";
import { resetPassword } from "../../core/services/auth.service";
import { history } from "../../core/helpers/history";

const itemStyle = { marginBottom: 16 };
const inputStyle = { height: 40, borderRadius: 4 };

const getLabel = (text: string) => {
  return <span style={{ fontSize: 15, fontFamily: "Karla" }}>{text}</span>;
};

const ResetPassword = () => {
  const location = useLocation<any>(); // TODO: add type
  const [done, setDone] = useState<boolean>(); // TODO: add type
  const [form] = Form.useForm();

  const reduxState = useSelector((state: RootState) => ({
    token: state.authReducer.token,
  }));

  const tokenFromQuery = new URLSearchParams(location.search).get("token");

  // If a token is present then the user successfully logged in
  // and we should redirect him to the previous page or Home
  if (reduxState.token) {
    return <Redirect to={location.state?.from || routes.home} />;
  }

  if (done) {
    return (
      <Row justify={"center"} align={"middle"} style={{ minHeight: "100vh" }}>
        <Card style={{ width: 432, borderRadius: 10 }} bodyStyle={{ padding: 56 }}>
          Done, you can go to the app and log in now.
        </Card>
      </Row>
    );
  }

  return (
    <Row justify={"center"} align={"middle"} style={{ minHeight: "100vh" }}>
      <Card style={{ width: 432, borderRadius: 10 }} bodyStyle={{ padding: 56 }}>
        <Row justify={"center"}>
          <Typography.Text style={{ fontSize: 24, marginBottom: 32 }}>
            Reset Password
          </Typography.Text>
        </Row>

        <Form
          layout={"vertical"}
          form={form}
          onFinish={async (values) => {
            if (
              !values ||
              !tokenFromQuery ||
              !values.newPassword ||
              !values.newPasswordConfirmation
            ) {
              return notification["error"]({
                message: "No valid data",
              });
            }

            await resetPassword({
              resetToken: tokenFromQuery,
              newPassword: values.newPassword,
              newPasswordConfirmation: values.newPasswordConfirmation,
            })
              .then(() => {
                setDone(true);
              })
              .catch(() => {
                notification["error"]({
                  message: "Something went wrong, try again",
                });
              });
          }}
        >
          <Form.Item
            name={"newPassword"}
            label={getLabel("New password")}
            rules={[{ required: true, message: "Please input your new password!" }]}
            required={false}
            style={itemStyle}
          >
            <Input.Password placeholder={"New password"} style={inputStyle} />
          </Form.Item>

          <Form.Item
            name={"newPasswordConfirmation"}
            label="New password confirmation"
            rules={[{ required: true, message: "Please input your new password confirmation!" }]}
            required={false}
            style={itemStyle}
          >
            <Input.Password placeholder={"New password confirmation"} style={inputStyle} />
          </Form.Item>

          <Row justify={"center"}>
            <Form.Item style={{ marginBottom: 32 }}>
              <Button
                onClick={() => {
                  void form.validateFields();

                  form.submit();
                }}
                type="primary"
                style={{
                  backgroundColor: "#498B9B",
                  height: "auto",
                  borderRadius: 20,
                  paddingTop: 7,
                  paddingBottom: 7,
                  paddingLeft: 32,
                  paddingRight: 32,
                  fontSize: 15,
                  fontFamily: "Karla",
                }}
              >
                Reset
              </Button>
            </Form.Item>

            {/*TODO: Change text color*/}
            <Typography.Text style={{ fontSize: 15, fontFamily: "Karla", color: "#0F322B" }}>
              You already have an account?{" "}
              <Link style={{ color: "#498B9B" }} to={routes.login}>
                Login
              </Link>
            </Typography.Text>
          </Row>
        </Form>
      </Card>
    </Row>
  );
};

export default ResetPassword;
