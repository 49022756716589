import React, { useState } from "react";
import { PageHeader, Table, Input } from "antd";
import { useTextList } from "../../core/hooks/queries/texts.queries";
import moment from "moment";
import CreateTextModal from "./CreateTextModal";
import { AgeRange, MediaCategory } from "../../types";
import { AppstoreTwoTone, DeleteOutlined, TagOutlined } from "@ant-design/icons";
import { ageRangeMappings, mediaCategoryMappings } from "../Media/MediaList";
import { deleteById } from "../../core/services/texts.service";
import { useSelector } from "react-redux";
import { RootState } from "../../core/redux/store";

const TextList = () => {
  const token = useSelector((state: RootState) => state.authReducer.token);
  const [searchQuery, seySearchQuery] = useState("");
  const allTexts = useTextList({
    searchQuery,
  });

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      render: (id: number) => id,
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      // eslint-disable-next-line react/display-name
      render: (category: MediaCategory) => {
        let iconColor = "black";

        switch (category) {
          case "STORIES":
            iconColor = "#c8a332";
            break;
          case "MEDITATIONS":
            iconColor = "#3288c8";
            break;
          case "SONGS":
            iconColor = "#c83261";
            break;
        }

        return (
          <>
            <AppstoreTwoTone twoToneColor={iconColor} />{" "}
            {mediaCategoryMappings[category] || "Unknown"}
          </>
        );
      },
    },
    {
      title: "Age range",
      dataIndex: "ageRange",
      key: "ageRange",
      // eslint-disable-next-line react/display-name
      render: (type: AgeRange) => {
        return (
          <>
            <TagOutlined /> {ageRangeMappings[type] || "Unknown"}
          </>
        );
      },
    },
    {
      title: "Danish Title",
      dataIndex: "title",
      render: (name: string) => name,
    },
    {
      title: "English Title",
      dataIndex: "en_title",
      render: (name: string) => name,
    },
    {
      title: "EN (f)",
      dataIndex: "en_female",
      render: (name: string) => String(!!name),
    },
    {
      title: "EN (m)",
      dataIndex: "en_male",
      render: (name: string) => String(!!name),
    },
    {
      title: "DA (f)",
      dataIndex: "da_female",
      render: (name: string) => String(!!name),
    },
    {
      title: "DA (m)",
      dataIndex: "da_male",
      render: (name: string) => String(!!name),
    },
    {
      title: "Characters",
      dataIndex: "keys",
      render: (array: string) => array.length,
    },
    {
      title: "Created at",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (date: string) => moment(date).format("DD MMM YYYY mm:ss"),
    },
    {
      title: "Updated at",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (date: string) => moment(date).format("DD MMM YYYY mm:ss"),
    },
    {
      title: "Actions",
      dataIndex: "id",
      key: "id",
      // eslint-disable-next-line react/display-name
      render: (id: number) => (
        <>
          <DeleteOutlined
            onClick={async () => {
              await deleteById(token, id).then(() => {
                void allTexts.refetch();
              });
            }}
          />
        </>
      ),
    },
  ];

  return (
    <div>
      <PageHeader
        title={"Texts"}
        extra={[
          <Input.Search
            key={1}
            placeholder="Search for texts"
            allowClear
            onChange={(event) => seySearchQuery(event.target.value)}
            value={searchQuery}
            style={{ width: 200, margin: "0 10px" }}
          />,
          <CreateTextModal
            key={2}
            onFinish={() => {
              void allTexts.refetch();
            }}
          />,
        ]}
      >
        <Table
          rowKey={"id"}
          columns={columns}
          dataSource={allTexts.data}
          onRow={(record) => ({
            // onClick: () => history.push(routes.event.generate(record.id)),
          })}
        />
      </PageHeader>
    </div>
  );
};

export default TextList;
