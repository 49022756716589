import axios, { AxiosInstance } from "axios";
import {
  API_ALL_USERS_PATH,
  API_BASE_URL,
  API_TIMEOUT,
  API_USERS_PATH,
  API_USERS_PROFILE_PATH,
} from "../constants/constants";
import { User, UserProfile } from "../../types";

// Create the Axios instance
const service: AxiosInstance = axios.create({
  baseURL: API_BASE_URL + API_USERS_PATH,
  timeout: API_TIMEOUT,
});

/**
 * Get user's profile
 * @param token
 */
export const getProfile = async (token: string | null): Promise<UserProfile> => {
  const response = await service.get<UserProfile>(API_USERS_PROFILE_PATH, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  // TODO
  //  - Catch errors
  //  - Validate the response

  return response.data;
};

/**
 * Get user's profile
 * @param token
 * @param searchQuery
 */
export const getAllUsers = async (token: string | null, searchQuery?: string): Promise<User[]> => {
  const response = await service.get<User[]>(API_ALL_USERS_PATH, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      searchQuery,
    },
  });

  // TODO
  //  - Catch errors
  //  - Validate the response

  return response.data;
};
