import {
  AuthActionTypes,
  AuthState,
  REMOVE_TOKEN,
  RESET_REGISTRATION,
  SET_AS_SUCCESSFULLY_REGISTRATION,
  SET_TOKEN,
  UPDATE_CURRENT_USER,
} from "./auth.types";

const initialState: AuthState = {
  successfullyRegistration: false,
  token: null,
  currentUser: null,
};

export default (state = initialState, action: AuthActionTypes): AuthState => {
  switch (action.type) {
    case SET_AS_SUCCESSFULLY_REGISTRATION: {
      return {
        ...state,
        successfullyRegistration: true,
      };
    }

    case RESET_REGISTRATION: {
      return {
        ...state,
        successfullyRegistration: false,
      };
    }

    case SET_TOKEN: {
      return {
        ...state,
        token: action.token,
      };
    }

    case REMOVE_TOKEN: {
      return {
        ...state,
        token: null,
      };
    }

    case UPDATE_CURRENT_USER: {
      return {
        ...state,
        currentUser: action.user,
      };
    }

    default: {
      return state;
    }
  }
};
