import {
  AuthActionTypes,
  REMOVE_TOKEN,
  RESET_REGISTRATION,
  SET_AS_SUCCESSFULLY_REGISTRATION,
  SET_TOKEN,
  UPDATE_CURRENT_USER,
} from "./auth.types";
import { login, logout, register } from "../../services/auth.service";
import { LoginRequest, RegisterRequest } from "../../../types";

// TODO:
//  - add 'dispatch' type
//  - decide if we should move the validations in the service

export const setAsSuccessfullyRegistration = (): AuthActionTypes => ({
  type: SET_AS_SUCCESSFULLY_REGISTRATION,
});

export const resetRegistration = (): AuthActionTypes => ({
  type: RESET_REGISTRATION,
});

export const setToken = (token: string): AuthActionTypes => ({
  type: SET_TOKEN,
  token,
});

export const removeToken = (): AuthActionTypes => ({
  type: REMOVE_TOKEN,
});

export const updateCurrentUser = (user: any): AuthActionTypes => ({
  type: UPDATE_CURRENT_USER,
  user,
});

/**
 * Register action
 * @param data
 */
export const requestRegister = (data: RegisterRequest) => {
  return async (dispatch: any) => {
    try {
      const response = await register(data);
      dispatch(setToken(response.access_token));
      // history.replace(routes.login, { successfullyRegistered: true });
    } catch (e) {
      // TODO: detect the real error/s
      console.log("Register action error -->", e);
    } finally {
    }
  };
};

/**
 * Login action
 * @param data
 */
export const requestLogin = (data: LoginRequest) => {
  return async (dispatch: any) => {
    try {
      const response = await login(data);
      dispatch(setToken(response.access_token));
    } catch (e) {
      // TODO: detect the real error/s
      console.log("Login action error -->", e);
    } finally {
    }
  };
};

/**
 * Logout action
 */
export const requestLogout = () => {
  return async (dispatch: any) => {
    try {
      await logout();
      dispatch(removeToken());
    } catch (e) {
      // TODO: detect the real error/s
      console.log("Logout action error -->", e);
    } finally {
    }
  };
};

// /**
//  * Forgot password action
//  */
// export const requestForgotPassword = (email: string) => {
//   return async (dispatch: any) => {
//     try {
//       dispatch(setAuthLoadingState(true));
//
//       await forgotPassword(email);
//
//       return true;
//     } catch (e) {
//       console.log('Forgot password action error -->', e);
//       // TODO: detect the real error/s
//       // dispatch(setAuthEmailError('Email not found'));
//       // dispatch(setAuthPasswordError('Wrong password'));
//       dispatch(setAuthGeneralLoginError(texts.AUTH_FORGOT_PASSWORD_API_ERROR));
//     } finally {
//       dispatch(setAuthLoadingState(false));
//     }
//   };
// };
//
// /**
//  * Reset password action
//  */
// export const requestResetPassword = (
//   token: string,
//   newPassword: string,
//   confirmNewPassword: string,
// ) => {
//   return async (dispatch: any) => {
//     try {
//       dispatch(setAuthLoadingState(true));
//
//       const response = await resetPassword(
//         token,
//         newPassword,
//         confirmNewPassword,
//       );
//
//       dispatch(setToken(response.token));
//     } catch (e) {
//       console.log('Reset password action error -->', e);
//       // TODO: detect the real error/s
//       // dispatch(setAuthEmailError('Email not found'));
//       // dispatch(setAuthPasswordError('Wrong password'));
//       dispatch(setAuthGeneralLoginError(texts.AUTH_RESET_API_ERROR));
//     } finally {
//       dispatch(setAuthLoadingState(false));
//     }
//   };
// };
//
//
// /**
//  * Get my user action
//  */
// export const requestGetMyUser = (): AppThunk => {
//   return async (dispatch, getState) => {
//     try {
//       const token = getState().authReducer.token;
//       if (!token) {
//         return; // TODO: throw and handle error. ex: dispatch(showGlobalError(...))
//       }
//
//       const user = await getMyUser(token);
//
//       dispatch(updateCurrentUser(user));
//     } catch (e) {
//       console.log('Get my user action error -->', e);
//     } finally {
//     }
//   };
// };
//
// /**
//  * Update profile action
//  */
// export const requestUpdateProfile = (
//   updatedUser: any,
// ): AppThunk => {
//   return async (dispatch, getState) => {
//     try {
//       const token = getState().authReducer.token;
//       if (!token) {
//         return; // TODO: throw and handle error. ex: dispatch(showGlobalError(...))
//       }
//
//       dispatch(setUpdateProfileLoadingState(true));
//
//       await updateProfile(token, updatedUser);
//
//       // TODO: replace this with the result of the 'updateProfile(...)'
//       dispatch(requestGetMyUser());
//
//       return true;
//     } catch (e) {
//       console.log('Update profile action error -->', e);
//     } finally {
//       dispatch(setUpdateProfileLoadingState(false));
//     }
//   };
// };
