import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { useQuery } from "react-query";
import { Text, TextListRequestParams } from "../../../types";
import { getAll } from "../../services/texts.service";

const ALL_TEXTS_KEY = "ALL_TEXTS_KEY";

export const useTextList = (params: TextListRequestParams) => {
  const reduxState = useSelector((state: RootState) => ({
    token: state.authReducer.token,
  }));

  return useQuery<Text[], Error>([ALL_TEXTS_KEY, params], () => {
    return getAll(reduxState.token, params);
  });
};
