import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { useQuery } from "react-query";
import { Role } from "../../../types";
import { getAllRoles } from "../../services/roles.service";

const ALL_ROLES_KEY = "ALL_ROLES_KEY";

export const useAllRoles = (searchQuery: string) => {
  const reduxState = useSelector((state: RootState) => ({
    token: state.authReducer.token,
  }));

  return useQuery<Role[], Error>([ALL_ROLES_KEY, searchQuery], () => {
    return getAllRoles(reduxState.token, searchQuery);
  });
};
