/**
 * Created to control react-route from outside of the context (e.x Redux actions)
 *
 * USAGE: The object return must be passed to the main router like this:
 *  <Router history={history}>
 *
 * ATTENTION: The `history` package version must be the same as the one used by `react-router-dom`,
 * otherwise you will experience problems like white screen after push.
 *
 * More info: https://github.com/ReactTraining/react-router/issues/7415
 */
import { createBrowserHistory } from "history";

export const history = createBrowserHistory();
