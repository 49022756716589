export default {
  register: "/register",
  login: "/login",
  resetPassword: "/reset-password",

  home: "/",
  about: "/about",
  users: "/users",
  roles: "/roles",
  media: "/media",
  texts: "/texts",
  news: "/news",
  user: { route: "/user/:userId", generate: (userId: number) => `/user/${userId}` },
};
