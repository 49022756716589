import React from "react";
import { PageHeader, Row, Tag } from "antd";
import { history } from "../../core/helpers/history";
import routes from "../../core/constants/routes";

const User = () => {
  // const { userId } = useParams<any>();

  return (
    <div>
      <PageHeader
        onBack={() => history.push(routes.users)}
        title={"User"}
        className="site-page-header"
        // subTitle={moment(eventQuery.data?.dueDate).format('DD-MM-YYYY')}
        tags={[
          <Tag key={"date"} color="blue">
            My tag
          </Tag>,
        ]}
        extra={[<div key={1}>Something</div>]}
      >
        <Row>Details</Row>
      </PageHeader>
    </div>
  );
};

export default User;
