import React, { useState } from "react";
import { PageHeader, Table, Input } from "antd";
import { useAllRoles } from "../../core/hooks/queries/roles.queries";

const RoleList = () => {
  const [searchQuery, seySearchQuery] = useState("");
  const allUsers = useAllRoles(searchQuery);

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
      render: (id: number) => id,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (name: string) => name,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (description: string) => description,
    },
  ];

  return (
    <div>
      <PageHeader
        title={"Roles"}
        extra={[
          <Input.Search
            key={1}
            placeholder="Search for roles"
            allowClear
            onChange={(event) => seySearchQuery(event.target.value)}
            value={searchQuery}
            style={{ width: 200, margin: "0 10px" }}
          />,
        ]}
      >
        <Table
          rowKey={"id"}
          columns={columns}
          dataSource={allUsers.data}
          onRow={(record) => ({
            // onClick: () => history.push(routes.event.generate(record.id)),
          })}
        />
      </PageHeader>
    </div>
  );
};

export default RoleList;
