import React, { useState } from "react";
import { PageHeader, Table, Input, Select, Avatar } from "antd";
import { useMediaList } from "../../core/hooks/queries/media.queries";
import { MediaCategory, MediaType, User } from "../../types";
import prettyBytes from "pretty-bytes";
import { useAllUsers } from "../../core/hooks/queries/users.queries";
import {
  AppstoreOutlined,
  AppstoreTwoTone,
  DeleteOutlined,
  EyeOutlined,
  SearchOutlined,
  TagOutlined,
  UserOutlined,
} from "@ant-design/icons";
import moment from "moment";
import CreateMediaModal from "./CreateMediaModal";
import { deleteById } from "../../core/services/media.service";
import { useSelector } from "react-redux";
import { RootState } from "../../core/redux/store";

export const mediaCategoryMappings = {
  STORIES: "Stories",
  MEDITATIONS: "Meditations",
  SONGS: "Songs",
};

export const ageRangeMappings = {
  ZERO_PLUS: "0+",
  THREE_PLUS: "3+",
};

export const mediaTypeMappings = {
  RECORDING: "Recording",
  AUDIO: "Audio",
  WHITE_NOISE: "White noise",
  LULLABEE: "Lullabee",
};

const MediaList = () => {
  const token = useSelector((state: RootState) => state.authReducer.token);
  const [searchValue, setSearchQuery] = useState<string | undefined>(undefined);
  const [userSearchValue, setUserSearchValue] = useState<string | undefined>();
  const [userId, setUserId] = useState<number | undefined>();
  const [category, setCategory] = useState<MediaCategory | undefined>();
  const [type, setType] = useState<MediaType | undefined>();
  const [isPublic, setIsPublic] = useState<boolean | undefined>(undefined);
  const [hasText, setHasText] = useState<boolean | undefined>(undefined);
  const mediaList = useMediaList({
    searchQuery: searchValue,
    category,
    userId,
    type,
    isPublic,
    hasText,
  });

  const userList = useAllUsers(userSearchValue);

  const columns = [
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      // eslint-disable-next-line react/display-name
      render: (category: MediaCategory) => {
        let iconColor = "black";

        switch (category) {
          case "STORIES":
            iconColor = "#c8a332";
            break;
          case "MEDITATIONS":
            iconColor = "#3288c8";
            break;
          case "SONGS":
            iconColor = "#c83261";
            break;
        }

        return (
          <>
            <AppstoreTwoTone twoToneColor={iconColor} />{" "}
            {mediaCategoryMappings[category] || "Unknown"}
          </>
        );
      },
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      // eslint-disable-next-line react/display-name
      render: (type: MediaType) => {
        return (
          <>
            <TagOutlined /> {mediaTypeMappings[type] || "Unknown"}
          </>
        );
      },
    },
    {
      title: "Danish Title",
      dataIndex: "title",
      key: "title",
      render: (name: string) => name,
    },
    {
      title: "English Title",
      dataIndex: "en_title",
      key: "en_title",
      render: (name: string) => name,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (description: string) => description,
    },
    {
      title: "File name",
      dataIndex: "originalName",
      key: "originalName",
      render: (originalName: string) => originalName,
    },
    {
      title: "Have text",
      dataIndex: "text",
      key: "text",
      render: (object: object) => String(!!object),
    },
    {
      title: "Size",
      dataIndex: "size",
      key: "size",
      render: (size: number) => prettyBytes(size),
    },
    {
      title: "Created at",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (date: string) => moment(date).format("DD MMM YYYY mm:ss"),
    },
    {
      title: "Updated at",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (date: string) => moment(date).format("DD MMM YYYY mm:ss"),
    },
    {
      title: "Added by",
      dataIndex: "user",
      key: "user",
      // eslint-disable-next-line react/display-name
      render: (user: User) => (
        <>
          <Avatar size="small">
            {user.firstName?.charAt(0)}
            {user.lastName?.charAt(0)}
          </Avatar>{" "}
          {user.firstName} {user.lastName}
        </>
      ),
    },
    {
      title: "URL",
      dataIndex: "url",
      key: "url",
      // eslint-disable-next-line react/display-name
      render: (url: string) => (
        <a href={url} target={"BLANK"}>
          Open file
        </a>
      ),
    },
    {
      title: "Actions",
      dataIndex: "id",
      key: "id",
      // eslint-disable-next-line react/display-name
      render: (id: number) => (
        <>
          <DeleteOutlined
            onClick={async () => {
              await deleteById(token, id).then(() => {
                void mediaList.refetch();
              });
            }}
          />
        </>
      ),
    },
  ];

  return (
    <div>
      <PageHeader
        title={"Media"}
        extra={[
          <Input
            key={1}
            allowClear
            style={{ width: 250, marginLeft: 10 }}
            suffix={<SearchOutlined />}
            placeholder="Title, description or url"
            onChange={(event) => setSearchQuery(event.target.value)}
            value={searchValue}
          />,
          <Select
            key={2}
            allowClear
            style={{ width: 160, marginLeft: 10 }}
            suffixIcon={<AppstoreOutlined />}
            placeholder="In category"
            onSelect={(value: MediaCategory) => setCategory(value)}
            onClear={() => setCategory(undefined)}
            // @ts-ignore
            options={Object.entries(mediaCategoryMappings).map(([value, label]) => ({
              label,
              value,
            }))}
          />,
          <Select
            key={3}
            allowClear
            style={{ width: 160, marginLeft: 10 }}
            suffixIcon={<TagOutlined />}
            placeholder="Type"
            onSelect={(value: MediaType) => setType(value)}
            onClear={() => setType(undefined)}
            // @ts-ignore
            options={Object.entries(mediaTypeMappings).map(([value, label]) => ({
              label,
              value,
            }))}
          />,
          <Select
            key={4}
            showSearch
            allowClear
            style={{ width: 160, marginLeft: 10 }}
            suffixIcon={<UserOutlined />}
            placeholder="Added by"
            loading={userList.isLoading}
            defaultActiveFirstOption={false}
            onSearch={(value) => setUserSearchValue(value || undefined)}
            onSelect={(value: number) => setUserId(value)}
            filterOption={false}
            onBlur={() => setUserSearchValue(undefined)}
            onClear={() => setUserId(undefined)}
            // notFoundContent={false}
            options={userList.data?.map((user): any => ({
              label: (
                <>
                  <Avatar size="small">
                    {user.firstName?.charAt(0)}
                    {user.lastName?.charAt(0)}
                  </Avatar>{" "}
                  {user.firstName} {user.lastName}
                </>
              ),
              value: user.id,
            }))}
          />,
          <Select
            key={5}
            allowClear
            style={{ width: 160, marginLeft: 10 }}
            suffixIcon={<EyeOutlined />}
            placeholder="Status"
            onSelect={(value) => setIsPublic(value === "public")}
            onClear={() => setIsPublic(undefined)}
            // @ts-ignore
            options={[
              { label: "Public", value: "public" },
              { label: "Private", value: "private" },
            ]}
          />,
          <Select
            key={6}
            allowClear
            style={{ width: 160, marginLeft: 10 }}
            suffixIcon={<EyeOutlined />}
            placeholder="Text"
            onSelect={(value) => setHasText(value === "yes")}
            onClear={() => setHasText(undefined)}
            // @ts-ignore
            options={[
              { label: "With text", value: "yes" },
              { label: "Without text", value: "no" },
            ]}
          />,
          <CreateMediaModal
            key={7}
            onFinish={() => {
              void mediaList.refetch();
            }}
          />,
        ]}
      >
        <Table
          loading={mediaList.isLoading}
          rowKey={"id"}
          columns={columns}
          dataSource={mediaList.data}
          // onRow={(record) => ({
          //   onClick: () => history.push(routes.event.generate(record.id)),
          // })}
        />
      </PageHeader>
    </div>
  );
};

export default MediaList;
