import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { useQuery } from "react-query";
import { Media, MediaListRequestParams } from "../../../types";
import { getAll } from "../../services/media.service";

const ALL_MEDIA_KEY = "ALL_MEDIA_KEY";

export const useMediaList = (params: MediaListRequestParams) => {
  const reduxState = useSelector((state: RootState) => ({
    token: state.authReducer.token,
  }));

  return useQuery<Media[], Error>([ALL_MEDIA_KEY, params], () => {
    return getAll(reduxState.token, params);
  });
};
