import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { useQuery } from "react-query";
import { News, NewsListRequestParams } from "../../../types";
import { getAll } from "../../services/news.service";

const ALL_NEWS_KEY = "ALL_NEWS_KEY";

export const useNewsList = (params: NewsListRequestParams) => {
  const reduxState = useSelector((state: RootState) => ({
    token: state.authReducer.token,
  }));

  return useQuery<News[], Error>([ALL_NEWS_KEY, params], () => {
    return getAll(reduxState.token, params);
  });
};
