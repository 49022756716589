import React, { useState } from "react";
import { Button, Form, Input, Modal, Row, Space } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { RootState } from "../../core/redux/store";
import { create } from "../../core/services/news.service";

const itemStyle = { marginBottom: 16 };

export type CreateNewsModalProps = {
  onFinish?: () => void;
};

const CreateNewsModal: React.FC<CreateNewsModalProps> = (props) => {
  const token = useSelector((state: RootState) => state.authReducer.token);
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [newsForm] = Form.useForm();
  const [newsFormValues, setNewsFormValues] = useState({});

  const toggleModal = () => setShowModal(!showModal);

  const onCancel = () => {
    setShowModal(false);
    setNewsFormValues({});
  };

  /**
   * Check the form and send the values
   */
  const onSubmit = async () => {
    setIsLoading(true);

    try {
      await newsForm.validateFields();
    } catch (e) {
      setIsLoading(false);
      return;
    }

    try {
      // @ts-ignore
      await create(token, newsFormValues);

      if (props?.onFinish) props.onFinish();

      onCancel();
    } catch (errorInfo) {
      console.log("Failed:", errorInfo);
    } finally {
      setIsLoading(false);
    }
  };

  const newsFormView = (
    <Form
      form={newsForm}
      layout={"vertical"}
      onValuesChange={(_, values) => setNewsFormValues(values)}
    >
      <Form.Item
        required
        name={"title"}
        label="Title"
        style={itemStyle}
        rules={[{ required: true, message: "You must enter the title" }]}
      >
        <Input placeholder={"News title"} />
      </Form.Item>

      <Form.Item
        name={"description"}
        label="Description"
        style={itemStyle}
        rules={[{ required: true, message: "You must enter the description" }]}
      >
        <Input.TextArea
          placeholder={"Write your news..."}
          onChange={() => newsForm.validateFields()}
        />
      </Form.Item>
    </Form>
  );

  return (
    <>
      <Button type="primary" icon={<PlusOutlined />} onClick={toggleModal}>
        Create news
      </Button>

      <Modal
        title="Create news"
        visible={showModal}
        width={1000}
        footer={null}
        onCancel={onCancel}
        centered
        style={{
          borderRadius: 10,
        }}
      >
        {newsFormView}

        <Row justify={"end"}>
          <Form.Item style={{ marginBottom: 0 }}>
            <Space
              size={16}
              style={{
                alignItems: "flex-end",
              }}
            >
              <Button htmlType="button" loading={isLoading} onClick={onCancel}>
                Cancel
              </Button>

              <Button type="primary" loading={isLoading} icon={<PlusOutlined />} onClick={onSubmit}>
                Create
              </Button>
            </Space>
          </Form.Item>
        </Row>
      </Modal>
    </>
  );
};

export default CreateNewsModal;
