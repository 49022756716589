import React from "react";

const style = {
  backgroundColor: "rgb(22, 22, 22)",
};

const AuthLayout = (props: any) => {
  return <div style={style}>{props.children}</div>;
};

export default AuthLayout;
