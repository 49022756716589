import axios, { AxiosInstance } from "axios";
import {
  API_ALL_MEDIA_PATH,
  API_BASE_URL,
  API_MEDIA_PATH,
  API_TIMEOUT,
} from "../constants/constants";
import { CreateMediaRequestParams, Media, MediaListRequestParams } from "../../types";

// Create the Axios instance
const service: AxiosInstance = axios.create({
  baseURL: API_BASE_URL + API_MEDIA_PATH,
  timeout: API_TIMEOUT,
});

export const getAll = async (
  token: string | null,
  params?: MediaListRequestParams,
): Promise<Media[]> => {
  const response = await service.get<Media[]>(API_ALL_MEDIA_PATH, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params,
  });

  // TODO
  //  - Catch errors
  //  - Validate the response

  return response.data;
};

export const deleteById = async (token: string | null, id: number): Promise<void> => {
  await service.delete<Media[]>(`/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  // TODO
  //  - Catch errors
  //  - Validate the response
};

export const create = async (
  token: string | null,
  params?: CreateMediaRequestParams,
): Promise<Media[]> => {
  const formData = new FormData();

  params &&
    Object.keys(params).map((key) => {
      // @ts-ignore
      const value = params[key];
      typeof value !== "undefined" &&
        formData.append(key, typeof value === "boolean" ? String(value) : value);
    });

  const response = await service.post<Media[]>("/", formData, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
  });

  // TODO
  //  - Catch errors
  //  - Validate the response

  return response.data;
};
