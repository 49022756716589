import React, { useState } from "react";
import { Button, Col, Divider, Form, Input, Modal, PageHeader, Row, Select, Space } from "antd";
import { AppstoreOutlined, DeleteOutlined, PlusOutlined, TagOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { RootState } from "../../core/redux/store";
import { create } from "../../core/services/texts.service";
import { ageRangeMappings, mediaCategoryMappings, mediaTypeMappings } from "../Media/MediaList";

const itemStyle = { marginBottom: 16 };

export type CreateTextModalProps = {
  onFinish?: () => void;
};

const CreateTextModal: React.FC<CreateTextModalProps> = (props) => {
  const token = useSelector((state: RootState) => state.authReducer.token);
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [textForm] = Form.useForm();
  const [textFormValues, setTextFormValues] = useState({});

  const toggleModal = () => setShowModal(!showModal);

  const onCancel = () => {
    setShowModal(false);
    setTextFormValues({});
  };

  /**
   * Check the form and send the values
   */
  const onSubmit = async () => {
    setIsLoading(true);

    try {
      await textForm.validateFields();
    } catch (e) {
      setIsLoading(false);
      return;
    }

    try {
      // @ts-ignore
      await create(token, textFormValues);

      if (props?.onFinish) props.onFinish();

      onCancel();
    } catch (errorInfo) {
      console.log("Failed:", errorInfo);
    } finally {
      setIsLoading(false);
    }
  };

  const textFormView = (
    <Form
      form={textForm}
      layout={"vertical"}
      onValuesChange={(_, values) => setTextFormValues(values)}
    >
      <Form.Item name={"title"} label="Danish title" style={itemStyle}>
        <Input placeholder={"Media title"} />
      </Form.Item>

      <Form.Item name={"enTitle"} label="English title" style={itemStyle}>
        <Input placeholder={"Media title"} />
      </Form.Item>

      <Form.Item
        shouldUpdate
        name={"category"}
        label="Category"
        style={itemStyle}
        rules={[{ required: true, message: "You must select the category" }]}
      >
        <Select
          allowClear
          suffixIcon={<AppstoreOutlined />}
          placeholder="Category"
          // @ts-ignore
          options={Object.entries(mediaCategoryMappings).map(([value, label]) => ({
            label,
            value,
          }))}
        />
      </Form.Item>

      <Form.Item
        shouldUpdate
        name={"ageRange"}
        label="Age range"
        style={itemStyle}
        rules={[{ required: true, message: "You must select the age range" }]}
      >
        <Select
          allowClear
          // suffixIcon={<AppstoreOutlined />}
          placeholder="Age range"
          // @ts-ignore
          options={Object.entries(ageRangeMappings).map(([value, label]) => ({
            label,
            value,
          }))}
        />
      </Form.Item>

      <Form.Item
        name={"en_male"}
        label="English male text"
        style={itemStyle}
        rules={[{ required: true, message: "You must enter the english male text" }]}
      >
        <Input.TextArea
          placeholder={"Write your english male text..."}
          onChange={() => textForm.validateFields()}
        />
      </Form.Item>

      <Form.Item
        name={"en_female"}
        label="English female text"
        style={itemStyle}
        rules={[{ required: true, message: "You must enter the english female text" }]}
      >
        <Input.TextArea
          placeholder={"Write your english male text..."}
          onChange={() => textForm.validateFields()}
        />
      </Form.Item>

      <Form.Item name={"da_male"} label="Danish male text" style={itemStyle}>
        <Input.TextArea
          placeholder={"Write your english male text..."}
          onChange={() => textForm.validateFields()}
        />
      </Form.Item>

      <Form.Item name={"da_female"} label="Danish female text" style={itemStyle}>
        <Input.TextArea
          placeholder={"Write your english male text..."}
          onChange={() => textForm.validateFields()}
        />
      </Form.Item>

      <Form.List name="keys">
        {(fields, { add, remove }) => {
          return (
            <>
              <PageHeader
                title="Add dynamic characters"
                extra={[
                  <Button key={1} type="dashed" onClick={() => add()}>
                    <PlusOutlined /> Add field
                  </Button>,
                ]}
              />

              {fields.map((field, index) => (
                <div key={field.key}>
                  <Divider orientation={"left"}>
                    <DeleteOutlined style={{ color: "red" }} onClick={() => remove(index)} />{" "}
                    Character {index + 1}
                  </Divider>

                  <Row gutter={10}>
                    <Col span={8}>
                      <Form.Item
                        name={[index, "key"]}
                        label={`Replacement key`}
                        rules={[
                          { required: true, message: "You must enter the replacement key" },
                          {
                            required: true,
                            validator: async (rule, value) => {
                              const enMale = textForm?.getFieldValue("en_male") || undefined;
                              const enFemale = textForm?.getFieldValue("en_female") || undefined;
                              const daMale = textForm?.getFieldValue("da_male") || undefined;
                              const daFemale = textForm?.getFieldValue("da_female") || undefined;

                              if (enMale && !enMale.includes(value)) {
                                throw new Error();
                              }

                              if (enFemale && !enFemale.includes(value)) {
                                throw new Error();
                              }

                              if (daMale && !daMale.includes(value)) {
                                throw new Error();
                              }

                              if (daFemale && !daFemale.includes(value)) {
                                throw new Error();
                              }
                            },
                            message:
                              "You didn't use this key in your texts (en male, en female, da male or da female)",
                          },
                        ]}
                      >
                        <Input placeholder="Key" />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        name={[index, "gender"]}
                        label={`Character gender`}
                        rules={[
                          { required: true, message: "You must specify the character gender" },
                        ]}
                      >
                        <Input placeholder="Gender" />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        name={[index, "default"]}
                        label={`Default name`}
                        rules={[
                          {
                            required: true,
                            message: "You must specify the character's default name",
                          },
                        ]}
                      >
                        <Input placeholder="Default" />
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
              ))}
            </>
          );
        }}
      </Form.List>
    </Form>
  );

  return (
    <>
      <Button type="primary" icon={<PlusOutlined />} onClick={toggleModal}>
        Create text
      </Button>

      <Modal
        title="Create text"
        visible={showModal}
        width={1000}
        footer={null}
        onCancel={onCancel}
        centered
        style={{
          borderRadius: 10,
        }}
      >
        {textFormView}

        <Row justify={"end"}>
          <Form.Item style={{ marginBottom: 0 }}>
            <Space
              size={16}
              style={{
                alignItems: "flex-end",
              }}
            >
              <Button htmlType="button" loading={isLoading} onClick={onCancel}>
                Cancel
              </Button>

              <Button type="primary" loading={isLoading} icon={<PlusOutlined />} onClick={onSubmit}>
                Create
              </Button>
            </Space>
          </Form.Item>
        </Row>
      </Modal>
    </>
  );
};

export default CreateTextModal;
