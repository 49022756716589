import React from "react";
import { Redirect, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { RouteComponentProps, RouteProps } from "react-router";
import routes from "../constants/routes";

/**
 * Protect routes
 * @param props
 * @constructor
 */
const PrivateRoute = (props: RouteProps) => {
  const { component: Component, children, ...rest } = props;

  // Get the token from the Redux state
  // to check if the user is authenticated
  const reduxState = useSelector((state: RootState) => ({
    token: state.authReducer.token,
  }));

  // This will determine what to render
  const renderHandler = (props: RouteComponentProps<any>): React.ReactNode => {
    const { location } = props;

    // Check if the user is logged in, redirect if not
    if (!reduxState.token) {
      return (
        <Redirect
          to={{
            pathname: routes.login,
            state: { from: location },
          }}
        />
      );
    }

    // If a component was specified load it, otherwise load the children
    // this way we preserve react-router's default functionality
    return Component ? <Component {...props} /> : children;
  };

  // Return the updated route
  return <Route {...rest} render={renderHandler} />;
};

export default PrivateRoute;
