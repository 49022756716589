import React, { useEffect, useState } from "react";
import { Router } from "react-router-dom";
import { history } from "./core/helpers/history";
import AuthRoutes from "./routes/AuthRoutes";
import AppRoutes from "./routes/AppRoutes";

const SPLASH_SCREEN_MIN_DISPLAY_DURATION = 0;

type RootProps = {
  isReduxLoaded: boolean;
};

const Root = (props: RootProps) => {
  const { isReduxLoaded } = props;

  /**
   * Splash Screen Timeout
   *
   * Set app as 'ready' after the SplashScreen's minimum duration time has elapsed
   */
  const [isTimeoutElapsed, setIsTimeoutElapsed] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsTimeoutElapsed(true);
    }, SPLASH_SCREEN_MIN_DISPLAY_DURATION);

    return () => clearTimeout(timeout);
  }, []);

  /**
   * Router
   */
  const router = (
    <Router history={history}>
      <AuthRoutes />
      <AppRoutes />
    </Router>
  );

  /**
   * Loading component
   */
  const loading = <div>Loading...</div>;

  return isReduxLoaded && isTimeoutElapsed ? router : loading;
};

export default Root;
