export const SET_AS_SUCCESSFULLY_REGISTRATION = "SET_AS_SUCCESSFULLY_REGISTRATION";
export const RESET_REGISTRATION = "RESET_REGISTRATION";
export const SET_TOKEN = "SET_TOKEN";
export const REMOVE_TOKEN = "REMOVE_TOKEN";
export const UPDATE_CURRENT_USER = "UPDATE_CURRENT_USER";

/**
 * State interface
 */
export interface AuthState {
  successfullyRegistration: boolean;
  token: string | null;
  currentUser: any | null;
}

/**
 * Action interfaces
 */
interface SetAsSuccessfullyRegistration {
  type: typeof SET_AS_SUCCESSFULLY_REGISTRATION;
}

interface ResetRegistration {
  type: typeof RESET_REGISTRATION;
}

interface SetToken {
  type: typeof SET_TOKEN;
  token: string;
}

interface RemoveToken {
  type: typeof REMOVE_TOKEN;
}

interface UpdateCurrentUser {
  type: typeof UPDATE_CURRENT_USER;
  user: any;
}

export type AuthActionTypes =
  | SetAsSuccessfullyRegistration
  | ResetRegistration
  | SetToken
  | RemoveToken
  | UpdateCurrentUser;
