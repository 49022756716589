import axios, { AxiosInstance } from "axios";
import {
  API_ALL_NEWS_PATH,
  API_BASE_URL,
  API_NEWS_PATH,
  API_TIMEOUT,
} from "../constants/constants";
import { Media, News, NewsListRequestParams } from "../../types";

// Create the Axios instance
const service: AxiosInstance = axios.create({
  baseURL: API_BASE_URL + API_NEWS_PATH,
  timeout: API_TIMEOUT,
});

export const create = async (token: string | null, params?: News): Promise<News> => {
  const response = await service.post<News>("", params, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  // TODO
  //  - Catch errors
  //  - Validate the response

  return response.data;
};

export const getAll = async (
  token: string | null,
  params?: NewsListRequestParams,
): Promise<News[]> => {
  const response = await service.get<News[]>(API_ALL_NEWS_PATH, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params,
  });

  // TODO
  //  - Catch errors
  //  - Validate the response

  return response.data;
};

export const deleteById = async (token: string | null, id: number): Promise<void> => {
  await service.delete<News[]>(`/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  // TODO
  //  - Catch errors
  //  - Validate the response
};