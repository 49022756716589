import axios, { AxiosInstance } from "axios";
import {
  API_AUTH_LOGIN_PATH,
  API_AUTH_PATH,
  API_AUTH_REGISTER_PATH,
  API_AUTH_RESET_PASSWORD_PATH,
  API_BASE_URL,
  API_TIMEOUT,
} from "../constants/constants";
import { AccessTokens, LoginRequest, RegisterRequest, ResetPassword } from "../../types";

// Create the Axios instance
const service: AxiosInstance = axios.create({
  baseURL: API_BASE_URL + API_AUTH_PATH,
  timeout: API_TIMEOUT,
});

/**
 * Login the user
 * @param data
 */
export const login = async (data: LoginRequest): Promise<AccessTokens> => {
  const response = await service.post<AccessTokens>(API_AUTH_LOGIN_PATH, data);

  // TODO
  //  - Catch errors
  //  - Validate the response

  return response.data;
};

/**
 * Register the user
 * @param data
 */
export const register = async (data: RegisterRequest): Promise<AccessTokens> => {
  const response = await service.post<AccessTokens>(API_AUTH_REGISTER_PATH, data);

  // TODO
  //  - Catch errors
  //  - Validate the response

  return response.data;
};

/**
 * Reset password
 * @param data
 */
export const resetPassword = async (data: ResetPassword): Promise<AccessTokens> => {
  const response = await service.post<AccessTokens>(API_AUTH_RESET_PASSWORD_PATH, data);

  // TODO
  //  - Catch errors
  //  - Validate the response

  return response.data;
};

/**
 * Logout the user
 *
 * TODO: call the API to invalidate the session (e.g. remove access tokens)
 */
export const logout = async (): Promise<boolean> => true;
