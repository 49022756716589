import axios, { AxiosInstance } from "axios";
import {
  API_ALL_TEXTS_PATH,
  API_BASE_URL,
  API_TEXTS_PATH,
  API_TIMEOUT,
} from "../constants/constants";
import { News, Text, TextListRequestParams } from "../../types";

// Create the Axios instance
const service: AxiosInstance = axios.create({
  baseURL: API_BASE_URL + API_TEXTS_PATH,
  timeout: API_TIMEOUT,
});

export const create = async (token: string | null, params?: Text): Promise<Text> => {
  const response = await service.post<Text>("", params, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  // TODO
  //  - Catch errors
  //  - Validate the response

  return response.data;
};

export const getAll = async (
  token: string | null,
  params?: TextListRequestParams,
): Promise<Text[]> => {
  const response = await service.get<Text[]>(API_ALL_TEXTS_PATH, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params,
  });

  // TODO
  //  - Catch errors
  //  - Validate the response

  return response.data;
};

export const deleteById = async (token: string | null, id: number): Promise<void> => {
  await service.delete<Text[]>(`/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  // TODO
  //  - Catch errors
  //  - Validate the response
};