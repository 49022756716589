import React, { useState } from "react";
import { Input, PageHeader, Table } from "antd";
import { useAllUsers } from "../../core/hooks/queries/users.queries";
import moment from "moment";

const UserList = () => {
  const [searchQuery, seySearchQuery] = useState("");
  const allUsers = useAllUsers(searchQuery);

  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
      render: (id: number) => id,
    },
    {
      title: "First Name",
      dataIndex: "firstName",
      key: "firstName",
      render: (firstName: string) => firstName,
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      key: "lastName",
      render: (lastName: string) => lastName,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (email: string) => email,
    },
    {
      title: "Children",
      dataIndex: "children",
      key: "children",
      // eslint-disable-next-line react/display-name
      render: (list: string[]) => (list?.length ? list.join(", ") : <i>none</i>),
    },
    {
      title: "Created at",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (date: string) => moment(date).format("DD MMM YYYY mm:ss"),
    },
    {
      title: "Updated at",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (date: string) => moment(date).format("DD MMM YYYY mm:ss"),
    },
  ];

  return (
    <div>
      <PageHeader
        title={"Users"}
        extra={[
          <Input.Search
            key={1}
            placeholder="Search for users"
            allowClear
            onChange={(event) => seySearchQuery(event.target.value)}
            value={searchQuery}
            style={{ width: 200, margin: "0 10px" }}
          />,
        ]}
      >
        <Table
          rowKey={"id"}
          columns={columns}
          dataSource={allUsers.data}
          expandable={{ rowExpandable: () => false }}
          // onRow={(record) => ({
          //   // onClick: () => history.push(routes.event.generate(record.id)),
          // })}
        />
      </PageHeader>
    </div>
  );
};

export default UserList;
